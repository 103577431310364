const state = () => ({
  restaurantFetchCount: 0, // The count for how many times restaurants have been fetched
})
const mutations = {
  INCREMENT_RESTAURANT_FETCH_COUNT(state) {
    state.restaurantFetchCount++
  },
  RESET_RESTAURANT_FETCH_COUNT(state) {
    state.restaurantFetchCount = 0
  },
}

const actions = {
  incrementRestaurantFetchCount({ commit }) {
    commit('INCREMENT_RESTAURANT_FETCH_COUNT')
  },
  resetRestaurantFetchCount({ commit }) {
    commit('RESET_RESTAURANT_FETCH_COUNT')
  },
}

const getters = {
  restaurantFetchCount: (state) => state.restaurantFetchCount,
}

export default {
  namespaced: true, // Ensures the module is namespaced
  state,
  mutations,
  actions,
  getters,
}
