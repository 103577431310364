const state = () => ({
  wineClubPreselectedPlan: null,
  membershipTypes: [],
  membershipQuotes: { wineaccess: [], collaborators: [] },
})

const mutations = {
  SET_WINE_CLUB_PRESELECTED_PLAN(state, value) {
    state.wineClubPreselectedPlan = value
  },
  SET_MEMBERSHIP_TYPES(state, value) {
    state.membershipTypes = value
  },
  SET_MEMBERSHIP_QUOTES(state, value) {
    state.membershipQuotes = value
  },
}

const actions = {
  setWineClubPreselectedPlan({ commit }, value) {
    commit('SET_WINE_CLUB_PRESELECTED_PLAN', value)
  },
  async fetchMembershipTypes({ commit }) {
    try {
      commit(
        'SET_MEMBERSHIP_TYPES',
        (await this.$axios.get(`/api/membership/list/`)).data.results
      )
    } catch (e) {
      this.$sentry.captureException(e)
      throw e
    }
  },
  async fetchMembershipQuotes({ commit }) {
    try {
      commit(
        'SET_MEMBERSHIP_QUOTES',
        (await this.$axios.get(`/api/membership/quotes/`)).data
      )
    } catch (e) {
      this.$sentry.captureException(e)
      throw e
    }
  },
}

const getters = {
  wineClubPreselectedPlan: (state) => state.wineClubPreselectedPlan,
  membershipTypes: (state) => state.membershipTypes,
  membershipQuotes: (state) => state.membershipQuotes,
}

export default {
  state,
  mutations,
  actions,
  getters,
}
