import { cloneDeep } from 'lodash'
import {
  DEFAULT_CATEGORIES,
  DEFAULT_VARIETALS,
  updateFacetDefaults,
  updateVarietalFacetDefaults,
} from '~/utils/filters/categories'

function processFacetNames(facetNames) {
  const filterSlugToName = { wineries: {}, searchable_varietal_categories: {} }
  Object.keys(facetNames).forEach((facetCategory) => {
    filterSlugToName[facetCategory] = {}
    facetNames[facetCategory].forEach((filter) => {
      filterSlugToName[facetCategory][filter.slug] = filter.name
      if (facetCategory === 'varietal_categories' && filter.include_in_search) {
        filterSlugToName.searchable_varietal_categories[filter.slug] =
          filter.name
      }
    })
  })
  return filterSlugToName
}

// Convert facets into an array of filter objects
export function processFacets(metadata, rawFacets) {
  const facets = {
    categories: DEFAULT_CATEGORIES,
    varietals: DEFAULT_VARIETALS,
  }
  facets.price = rawFacets._filter_price.price.buckets
  facets.food_pairings = rawFacets._filter_food_pairings.food_pairings.buckets
  facets.vintage = rawFacets._filter_vintage.vintage.buckets
  facets.expert_reviewers =
    rawFacets._filter_expert_reviewers.expert_reviewers.buckets
  facets.occasions = rawFacets._filter_occasions.occasions.buckets
  if (rawFacets._filter_varietal_categories) {
    facets.varietal_categories =
      rawFacets._filter_varietal_categories.varietal_categories.buckets
  }
  facets.is_offer = rawFacets._filter_is_offer.is_offer.buckets
  facets.wineries = []

  updateFacetDefaults(
    'categories',
    facets,
    rawFacets._filter_categories.categories.buckets
  )
  updateVarietalFacetDefaults(
    facets,
    rawFacets._filter_varietals.varietals.buckets,
    metadata.varietals
  )

  // Add is_offer facet, if missing
  if (!facets.is_offer.some((f) => f.key === 1)) {
    facets.is_offer.push({ key: 1, key_as_string: 'true', doc_count: 0 })
  }

  let filters = []
  Object.keys(facets).forEach((facetCategory) => {
    let categoryFilters = facets[facetCategory].map((filter) => {
      filter.name = null
      if (!['price', 'is_offer', 'vintage'].includes(facetCategory)) {
        filter.name = metadata.facetNames[facetCategory][filter.key]
      } else if (facetCategory === 'is_offer') {
        if (filter.key === 0) {
          filter.name = 'Ships Immediately (The Store)'
        } else if (filter.key === 1) {
          filter.name = 'Limited Time Offer (Preorders)'
        }
      } else {
        filter.name = filter.key
      }
      filter.category = facetCategory
      filter.docCount = filter.doc_count

      // Delete redundant doc_count
      delete filter.doc_count

      return filter
    })
    /* Exclude filters which do not have a name, which would show up as blank
       checkboxes without a label in the UI.
       Occasions may be associated with a product, but if they are not present
       in metadata to indicate that they are homepage collections, do not expose
       them in filters */
    categoryFilters = categoryFilters.filter((item) => item.name)

    // Alphabetize occasions
    if (facetCategory === 'occasions') {
      categoryFilters.sort((a, b) => a.name.localeCompare(b.name))
    }
    // Alphabetize varietals
    if (facetCategory === 'varietals') {
      categoryFilters.sort((a, b) => a.name.localeCompare(b.name))
    }
    // Reverse alphabetize vintages (to essentially put them in reverse chronological order)
    if (facetCategory === 'vintage') {
      categoryFilters.sort((a, b) => b.name.localeCompare(a.name))
    }

    filters = filters.concat(categoryFilters)
  })

  return filters
}

// Map the category and slug to the index of the filter in the search filters array
export function generateFilterMapping(searchFilters) {
  const mapping = {}
  searchFilters.forEach((f, i) => {
    if (!mapping[f.category]) mapping[f.category] = {}
    mapping[f.category][f.key] = i
  })
  return mapping
}

const state = () => ({
  facetNames: {},
  varietalObjs: {},
  filters: [],
  constance: {},
  redWine: {},
  whiteWine: {},
  categories: {},
  food: [],
  occasions: [],
  gifting_menu_items: [],
  varietals: [],
  vintage: [],
  expert_reviewers: [],
  varietalCategories: {},
  settingsVars: {},
  tinycontent: {},
  seo: {},
  addressStateData: [],
  sortingOptions: [],
  states: [],
  header: {
    accounts_dropdown: false,
    items: {
      'All Wines': {
        link: '/store/',
      },
      Varietals: {
        link: null,
        active: false,
        submenu: {
          Reds: {
            link: '/store/red-wine/',
            active: false,
            submenu: {
              'Cabernet Sauvignon': {
                link: '/store/varietals/cabernet-sauvignon/',
              },
              'Pinot Noir': {
                link: '/store/varietals/pinot-noir/',
              },
              Zinfandel: {
                link: '/store/varietals/zinfandel/',
              },
              Syrah: {
                link: '/store/varietals/syrah/',
              },
              Merlot: {
                link: '/store/varietals/merlot/',
              },
              Sangiovese: {
                link: '/store/varietals/sangiovese/',
              },
              Nebbiolo: {
                link: '/store/varietals/nebbiolo/',
              },
              'Red Blends': {
                link: '/store/varietals/red-blend/',
              },
            },
          },
          Whites: {
            link: '/store/white-wine/',
            active: false,
            submenu: {
              Chardonnay: {
                link: '/store/varietals/chardonnay/',
              },
              'Pinot Grigio': {
                link: '/store/varietals/pinot-grigio/',
              },
              Riesling: {
                link: '/store/varietals/riesling/',
              },
              'Sauvignon Blanc': {
                link: '/store/varietals/sauvignon-blanc/',
              },
            },
          },
          'Sparkling & Rose': {
            link: '/store/sparkling-rose/',
            submenu: {},
          },
          Sake: {
            link: '/store/sake/',
            submenu: {},
          },
          SweetFortified: {
            link: '/store/sweet-fortified/',
            submenu: {},
          },
          'Shop All Wines': {
            link: '/store/',
            submenu: {},
          },
        },
      },
      Regions: {
        link: null,
        active: false,
        submenu: {
          California: {
            link: '/store/regions/california/',
            active: false,
            submenu: {
              'Napa Valley': {
                link: '/store/regions/napa-valley/',
              },
              'Sonoma County': {
                link: '/store/regions/sonoma-county/',
              },
              'Central Coast': {
                link: '/store/regions/central-coast/',
              },
            },
          },
          Oregon: {
            link: '/store/regions/oregon/',
            submenu: {},
          },
          'All North America': {
            link: '/store/regions/north-america/',
            submenu: {},
          },
          France: {
            link: '/store/regions/france/',
            active: false,
            submenu: {
              Bordeaux: {
                link: '/store/regions/bordeaux/',
              },
              Burgundy: {
                link: '/store/regions/burgundy/',
              },
              Champagne: {
                link: '/store/regions/champagne/',
              },
              'Loire Valley': {
                link: '/store/regions/loire/',
              },
              Rhone: {
                link: '/store/regions/rhone/',
              },
            },
          },
          Italy: {
            link: '/store/regions/italy/',
            active: false,
            submenu: {
              Piedmont: {
                link: '/store/regions/piedmont/',
              },
              Tuscany: {
                link: '/store/regions/tuscany/',
              },
            },
          },
          Spain: {
            link: '/store/regions/spain/',
            submenu: {},
          },
          'All Europe': {
            link: '/store/regions/europe/',
            submenu: {},
          },
          'South America': {
            link: '/store/regions/south-america/',
            active: false,
            submenu: {
              Argentina: {
                link: '/store/regions/argentina/',
                submenu: {},
              },
            },
          },
          Australia: {
            link: '/store/regions/australia/',
            submenu: {},
          },
          'New Zealand': {
            link: '/store/regions/new-zealand/',
            submenu: {},
          },
          Japan: {
            link: '/store/regions/japan/',
            submenu: {},
          },
          'Shop All Wines': {
            link: '/store/',
            submenu: {},
          },
        },
      },
      'Food Pairings': {
        active: false,
        submenu: {
          'Red Meat': {
            link: '/store/food_pairings/red-meat/',
            submenu: {},
            slug: 'red-meat',
            image: '',
          },

          'White Meat': {
            link: '/store/food_pairings/white-meat/',
            submenu: {},
            slug: 'white-meat',
            image: '',
          },
          'Cured Meat': {
            link: '/store/food_pairings/cured-meat/',
            submenu: {},
            slug: 'cured-meat',
            image: '',
          },
          Fish: {
            link: '/store/food_pairings/fish/',
            submenu: {},
            slug: 'fish',
            image: '',
          },
          Shellfish: {
            link: '/store/food_pairings/shellfish/',
            submenu: {},
            slug: 'shellfish',
            image: '',
          },
          'Hard Cheese': {
            link: '/store/food_pairings/hard-cheese/',
            submenu: {},
            slug: 'hard-cheese',
            image: '',
          },
          'Soft Cheese': {
            link: '/store/food_pairings/soft-cheese/',
            submenu: {},
            slug: 'soft-cheese',
            image: '',
          },
          Vegetables: {
            link: '/store/food_pairings/vegetables/',
            submenu: {},
            slug: 'vegetables',
            image: '',
          },
          Spicy: {
            link: '/store/food_pairings/spicy/',
            submenu: {},
            slug: 'spicy',
            image: '',
          },
          Dessert: {
            link: '/store/food_pairings/sweets/',
            submenu: {},
            slug: 'sweets',
            image: '',
          },
        },
      },
      Collections: {
        active: false,
        submenu: {},
      },
      'My Account': {
        active: false,
        submenu: {},
      },
      Categories: {
        active: false,
        submenu: {},
      },
      LimitedTimeOffers: {
        link: '/store/is_offer/true/',
      },
      ShipImmediatelyOffers: {
        link: '/store/is_offer/false/',
      },
      Port: {
        link: '/store/sweet-fortified/',
      },
      WineSets: {
        link: '/store/wine-sets/',
      },
      NotifyMe: {
        link: '/store/notify-me/',
      },
      Personalized: {
        link: '/store/personalized/',
      },
    },
  },
  podcast: {
    show_link: false,
  },
})

const mutations = {
  SET_CONSTANCE(state, payload) {
    const config = {}
    payload.forEach((item) => {
      config[item.key] = item.value
    })
    state.constance = config
  },
  SET_SETTINGS_VARS(state, payload) {
    state.settingsVars = payload
  },
  SET_CATEGORIES(state, payload) {
    state.categories = payload
  },
  SET_VARIETALS(state, payload) {
    state.varietals = payload
  },
  SET_VINTAGE(state, payload) {
    state.vintage = payload
  },
  SET_EXPERT_REVIEWERS(state, payload) {
    state.expert_reviewers = payload
  },
  SET_RED_WINE(state, payload) {
    state.redWine = payload
  },
  SET_WHITE_WINE(state, payload) {
    state.whiteWine = payload
  },
  SET_FOOD(state, payload) {
    state.food = payload
    const foodIcon = {}
    payload.forEach((item) => {
      foodIcon[item.slug] = item.icon
    })
    const subMenu = state.header.items['Food Pairings'].submenu
    state.header.items['Food Pairings'].submenu = Object.assign(
      {},
      subMenu,
      Object.keys(subMenu).forEach((key) => {
        if (subMenu[key].slug) {
          subMenu[key].image = foodIcon[subMenu[key].slug]
        }
      })
    )
  },
  SET_OCCASIONS(state, payload) {
    state.occasions = payload
    state.header.items.Collections.submenu = payload
  },
  SET_GIFTING_MENU_ITEMS(state, payload) {
    state.gifting_menu_items = payload
  },
  SET_FILTERS(state, payload) {
    state.filters = payload
  },
  SET_SORTING_OPTIONS(state, payload) {
    state.sortingOptions = payload
  },
  SET_FACET_NAMES(state, payload) {
    const tempNames = cloneDeep(state.facetNames)
    Object.keys(payload).forEach((key) => (tempNames[key] = payload[key]))
    state.facetNames = tempNames
  },
  SET_TINYCONTENT(state, payload) {
    const dict = {}
    payload.forEach((item) => {
      dict[item.key] = item.value
    })
    state.tinycontent = dict
  },
  SET_SEO(state, payload) {
    state.seo = payload
  },
  SET_VARIETAL_CATEGORIES(state, payload) {
    state.varietalCategories = payload
    state.header.items.Categories.submenu = payload
  },
  TOGGLE_ACCOUNTS_DROPDOWN(state) {
    state.header.accounts_dropdown = !state.header.accounts_dropdown
  },
  TOGGLE_ACTIVE_MOBILE_SUBMENU(state, payload) {
    state.header.items[payload.primary].submenu[
      payload.secondary
    ].active = !state.header.items[payload.primary].submenu[payload.secondary]
      .active
  },
  TOGGLE_ACTIVE_MOBILE_MENU(state, payload) {
    for (const i in state.header.items) {
      state.header.items[i].active = false
    }
    state.header.items[payload.primary].active = !state.header.items[
      payload.primary
    ].active
  },
  SET_PODCAST(state, payload) {
    state.podcast = payload
  },
  SET_ADDRESS_STATE_DATA(state, addressStateData) {
    state.addressStateData = addressStateData
  },
}

const actions = {
  changeHeaderActive({ commit }, payload) {
    if (payload.secondary) {
      commit('TOGGLE_ACTIVE_MOBILE_SUBMENU', payload)
    } else {
      commit('TOGGLE_ACTIVE_MOBILE_MENU', payload)
    }
  },
  fetchMetadata({ state, commit }) {
    // Fetches the metadata endpoint and also all constance settings
    return Promise.all([
      this.$axios.get('/api/metadata/').then((resp) => {
        const cats = {}
        resp.data.categories.forEach((item) => {
          cats[item.slug] = item
        })
        commit('SET_CATEGORIES', cats)
        commit('SET_RED_WINE', cats['red-wine'])
        commit('SET_WHITE_WINE', cats['white-wine'])
        commit('SET_FOOD', resp.data.food_pairings)
        commit('SET_REGIONS', resp.data.regions)
        commit('SET_SORTING_OPTIONS', resp.data.sorting_options)
        commit('SET_VARIETALS', resp.data.varietals)
        commit('SET_VINTAGE', resp.data.vintage)
        commit('SET_EXPERT_REVIEWERS', resp.data.expert_reviewers)

        const facetNames = resp.data.filter_names
        commit('SET_FACET_NAMES', processFacetNames(facetNames))

        commit('SET_SETTINGS_VARS', resp.data.settings_data)

        commit('SET_TINYCONTENT', resp.data.tinycontent)

        commit('SET_SEO', resp.data.seo)

        commit('SET_VARIETAL_CATEGORIES', resp.data.varietalCategories)

        commit('SET_PODCAST', resp.data.podcast)

        commit('SET_OCCASIONS', resp.data.collections)

        commit('SET_GIFTING_MENU_ITEMS', resp.data.gifting_menu_items)
      }),
      this.$axios.get('/api/metadata/constance/').then((resp) => {
        commit('SET_CONSTANCE', resp.data.config)
      }),
    ])
  },
  toggleAccounts({ commit }) {
    commit('TOGGLE_ACCOUNTS_DROPDOWN')
  },
  setAddressStateData({ commit }, addressStateData) {
    commit('SET_ADDRESS_STATE_DATA', addressStateData)
  },
}

const getters = {
  constance(state) {
    return state.constance
  },
  customerServiceNumber(state) {
    return (
      '1' + state.constance.CUSTOMER_SERVICE_PHONE_NUMBER.replace(/[^0-9]/g, '')
    )
  },
  customerServiceEmail(state) {
    return state.constance.CUSTOMER_SERVICE_EMAIL_ADDRESS
  },
  customerServiceClubEmail(state) {
    return state.constance.CUSTOMER_SERVICE_CLUB_EMAIL_ADDRESS
  },
  customerServiceNumberDisplay(state) {
    return state.constance.CUSTOMER_SERVICE_PHONE_NUMBER
  },
  customerServiceHoursLong(state) {
    return state.constance.CUSTOMER_SERVICE_HOURS_LONG
  },
  customerServiceHoursShort(state) {
    return state.constance.CUSTOMER_SERVICE_HOURS_SHORT
  },
  categories(state) {
    return state.categories
  },
  whiteWine(state) {
    return state.whiteWine
  },
  redWine(state) {
    return state.redWine
  },
  food(state) {
    return state.food
  },
  vintage(state) {
    return state.vintage
  },
  expert_reviewers(state) {
    return state.expert_reviewers
  },
  occasions(state) {
    return state.occasions
  },
  facetNames(state) {
    return state.facetNames
  },
  gifting_menu_items(state) {
    return state.gifting_menu_items
  },
  varietals(state) {
    return state.varietals
  },
  varietalCategories(state) {
    return state.varietalCategories
  },
  settingsVars(state) {
    return state.settingsVars
  },
  tinycontent(state) {
    return state.tinycontent
  },
  header(state) {
    return state.header
  },
  sortingOptions(state) {
    return state.sortingOptions
  },
  seoForPath(state) {
    // Return seo data for this `path`. Handle presence or lack of trailing slash
    // because nuxt is not configured to use trailing slashes everywhere but
    // django was, and seo data is defined as such.
    // Unpack the seoData object array - it's unclear what multiple seo data
    // associations would mean to a single route
    return (path) => {
      if (Object.keys(state.seo).includes(path)) {
        return state.seo[path]
      } else if (Object.keys(state.seo).includes(path + '/')) {
        return state.seo[path + '/']
      }
      return null
    }
  },
  podcastMetadata(state) {
    return state.podcast
  },
  addressStateData(state) {
    return state.addressStateData
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
